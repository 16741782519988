import {
  KINDS_SET,
  ROOMS_SET,
  AREAS_SET,
  RENTS_SET,
  DEPOSITS_SET,
} from "../constants";

const INITIAL_STATE = {
  kinds: [
    { slug: "phongtro", name: "Phòng Trọ", checked: true },
    { slug: "nguyencan", name: "Nguyên Căn", checked: true },
    { slug: "oghep", name: "Ở Ghép", checked: true },
    { slug: "datxuong", name: "Đất/Xưởng", checked: true },
  ],
  rooms: [
    { slug: "one", name: "1", checked: false },
    { slug: "two", name: "2", checked: false },
    { slug: "more", name: ">2", checked: false },
    { slug: "any", name: "Bất kỳ", checked: true },
  ],
  areas: {
    from: 30,
    to: 150,
  },
  rents: {
    from: 1000,
    to: 20000,
  },
  deposits: {
    from: 300,
    to: 30000,
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case KINDS_SET:
      state.kinds.map((item) => {
        if (item.slug === action.payload.slug) {
          item.checked = action.payload.checked;
        }

        return item;
      });

      return state;
    case ROOMS_SET:
      state.rooms.map((item) => {
        item.checked = item.slug === action.payload.slug;

        return item;
      });

      return state;
    case AREAS_SET:
      state.areas.from = action.payload.from;
      state.areas.to = action.payload.to;

      return state;
    case RENTS_SET:
      state.rents.from = action.payload.from;
      state.rents.to = action.payload.to;

      return state;
    case DEPOSITS_SET:
      state.deposits.from = action.payload.from;
      state.deposits.to = action.payload.to;

      return state;
    default:
      return state;
  }
};

export default reducer;
